var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        timeout: 6000,
        dark: "",
        color: "var(--gray-8)",
        right: _vm.$vuetify.breakpoint.smAndUp,
        "content-class": "pb-2",
        vertical: "",
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _vm.to
                ? _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: {
                          dark: "",
                          text: "",
                          right: "",
                          color: _vm.snackColor,
                          to: _vm.to.to,
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(_vm._s(_vm.to.label))]
                  )
                : _vm._e(),
              _vm.closeBtn
                ? _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: {
                          dark: "",
                          text: "",
                          right: "",
                          color: _vm.snackColor,
                        },
                        on: {
                          click: function ($event) {
                            _vm.snack = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("とじる")]
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
      model: {
        value: _vm.snack,
        callback: function ($$v) {
          _vm.snack = $$v
        },
        expression: "snack",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("v-icon", { attrs: { color: _vm.snackColor, left: "" } }, [
            _vm._v(_vm._s(_vm.snackIcon)),
          ]),
          _c("BaseText", { attrs: { text: _vm.msg } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }