var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "overlay-opacity": "1",
        fullscreen: "",
        transition: "fade-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          staticClass:
            "gDetail-IllPreview px-0 d-flex justify-center align-center",
          nativeOn: {
            click: function ($event) {
              return _vm.close.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "gDetail-IllPreview_BtnArea d-flex flex-column" },
            [
              _c(
                "v-btn",
                { attrs: { fab: "", elevation: "0", small: "" } },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
              _vm.isBigImg
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-3",
                      attrs: { fab: "", elevation: "0", small: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickFit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", [
                        _vm._v(
                          _vm._s(_vm.fitScreen ? "zoom_out_map" : "fit_screen")
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "gDetail-IllPreview_Wrap" }, [
            _c("div", { staticClass: "gDetail-IllPreview_Box" }, [
              _vm.dialog
                ? _c("img", {
                    ref: "target",
                    staticClass: "gDetail-IllPreview_Img ma-auto",
                    style: _vm.fitScreen ? _vm.imgStyle : "",
                    attrs: {
                      height: "auto",
                      width: "auto",
                      src: _vm.src,
                      contain: "",
                    },
                  })
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }