var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "sw-Dialog sw-Dialog-alert",
      attrs: { persistent: "", "max-width": "400" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "sw-Dialog_Title subtitle-1 font-weight-bold mb-4",
              staticStyle: { "background-color": "var(--gray-2)" },
            },
            [
              _vm.icon != null
                ? _c("v-icon", { staticClass: "pr-2" }, [
                    _vm._v(_vm._s(_vm.icon)),
                  ])
                : _vm._e(),
              _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "sw-Dialog_Text" },
            [
              _vm._t("default", function () {
                return [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } }),
                ]
              }),
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "sw-Dialog_BtnAre" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "sw-Dialog_Btn",
                  attrs: { text: "", color: _vm.ColorPrimary },
                  on: { click: _vm.discard },
                },
                [_vm._v(_vm._s(_vm.btnName))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }