var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sw-Loading" }, [
    _c("div", { staticClass: "sw-Loading_Box" }, [
      _c(
        "div",
        { staticClass: "sw-Loading_Inner" },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: _vm.color },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }